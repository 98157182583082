import React from "react";

import { PrismicRichText } from "@prismicio/react";
import { connect, useSelector } from "react-redux";
import { config } from "../../utils/constants";

import { LegalDocumentsQuery } from "../../prismic/staticQueries";
import Layout from "../../layout";
import { HeadingBanner, Subscription } from "../../components/common";
import PDF_ICON from "../../images/common/PDF_file_icon.png";
import BANNER from "../../images/siteImages/legalDocuments/BANNER_LD.jpg";
import PaymentSlider from "./PaymentSlider";

const OurMission = () => {
  const language = useSelector((state) => state.language);
  const {
    page_heading,
    heading_description,
    banner_image,
    legal_documents,
    content_description,
  } = LegalDocumentsQuery(language);

  // const query = graphql`
  //   query pdfIcon {
  //     file(
  //       relativePath: { eq: "common/PDF_file_icon.png" }
  //       sourceInstanceName: { eq: "images" }
  //     ) {
  //       publicURL
  //     }
  //   }
  // `;
  // const PDF_ICON = useStaticQuery(query).file.publicURL;

  const shortenUrl = (url = "") => {
    if (!url || url === "") {
      return "";
    }
    if (config.env === "development") {
      return url;
    } else {
      return url.replace(
        "https://stagging-accuindex.cdn.prismic.io/stagging-accuindex",
        "/legal-docs"
      );
    }
  };

  return (
    <>
      <Layout>
        <HeadingBanner heading={page_heading} banner_image={BANNER} />

        <section
          className="information-box quickStats p-top-140 p-bottom-60"
          style={{ paddingTop: "10px" }}
        >
          <div className="information-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 legal-documents">
                  <div className=" card shadow-lg-2 border-0">
                    <div className="card-header">
                      <h5
                        className="m-bottom-0"
                        style={{ fontWeight: "400", fontSize: "17px" }}
                      >
                        {heading_description.text}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container ">
            <div className="row">
              <div className="col-lg-12 all-p-without-margin-bottom">
                <PrismicRichText field={content_description.richText} />
              </div>
            </div>
          </div>
        </section>
        <section className="job-contents pt-2 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive table-bordered">
                  <table className="table table-one">
                    <thead className="table-secondary table-secondary--darken ">
                      <tr>
                        <th scope="col">Document Name</th>
                        <td align="center">Read / Download</td>
                      </tr>
                    </thead>
                    <tbody>
                      {legal_documents.map((document, ind) => (
                        <tr key={"docu-" + ind}>
                          <th scope="row"> {document.document_name.text} </th>
                          <td align="center">
                            <a
                              href={shortenUrl(document.document_file.url)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={PDF_ICON} alt="image" />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(OurMission);
